import React, { FC, useEffect, useState, useContext, useCallback } from 'react';

import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';


// import OverviewSection from './OverviewSection';
import TopColleges from './TopColleges';
import Features from './Features';
import Interested from './Interested';
import Location from './Location';

import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams, useHistory } from "react-router-dom";
import GlobalReviewSection from 'src/components/GlobalReviewSection';
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const OverviewSection = React.lazy(() => import('./OverviewSection'));
const HomePage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const setCtx = useContext(SettingsContext);

  const [bannerArr, setBannerArr] = useState([]);
  const [countBlockInfo, setCountBlockInfo] = useState({});
  const [courseStreamArr, setCourseStreamArr] = useState([]);

  const [topCollegeArr, setTopCollegeArr] = useState([]);
  const [topUniversityArr, setTopUniversityArr] = useState([]);
  const [topExamArr, setTopExamArr] = useState([]);
  const [testimonialsArr, setTestimonialsArr] = useState([]);

  const [blogArr, setBlogArr] = useState([]);
  const [newsArr, setNewsArr] = useState([]);
  const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [isTopColLoaded, setTopColLoaded] = useState(false);
  const [isBlogLoaded, setBlogLoaded] = useState(false);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [isSchoolLoaded, setSchoolLoaded] = useState(false);

  const isMountedRef = useIsMountedRef();
  const getHeroData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
    const heroResp = await axios.post('api/website/home/header', formData);

    //setBannerArr(heroResp.data.data.banners);
    //setCountBlockInfo(heroResp.data.data.count_block);
    setCourseStreamArr(heroResp.data.data.course_streams_head);

    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const getCollageUnivData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "college" }, { data: "university" }, { data: "exam" }, { data: "testimonial" }]));
    const univColgResp = await axios.post('api/website/home/topcrousel', formData);

    setTopCollegeArr(univColgResp.data.data.topcollege.data);
    setTopUniversityArr(univColgResp.data.data.topuniversity.data);
    setTopExamArr(univColgResp.data.data.topexam.data);
    setTestimonialsArr(univColgResp.data.data.testimonials.data)
    setTopColLoaded(true);
  }

  const geBlogData = async () => {
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{ data: "blogs" }, { data: "news" }]));
    const blogResp = await axios.post('api/website/home/blogsandnews', formData);

    console.log(blogResp.data.data.blogs.data)
    setBlogArr(blogResp.data.data.blogs.data);
    setNewsArr(blogResp.data.data.news.data);
    setBlogLoaded(true);
  }

  const [topschoolArr, settopschoolArr] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [HasMore, setHasMore] = useState(true);
  const [TopSchooldetail, setTopSchooldetail] = useState([]);

  const getSchoollist = useCallback(async () => {
    setIsFetching(true);
    try {
      const params = {}
      const response = await axios.get('api/website/home/topschoolbangalore');
      if (isMountedRef.current) {
        setPage(page);
        settopschoolArr(response.data.data);
        setHasMore(response.data.data.length > 0);
        setIsFetching(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const [schooldetail, setschooldetail] = useState<any>([]);
  const getdatabyschoolid = useCallback(async () => {
    try {

      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/school/get/' + id);
      if (isMountedRef.current) {
        //  alert("asfd");
        //console.log("response.data.data");
        //console.log(response.data.data);
        setschooldetail(response.data.data);
        setmetatitle(response.data.data.meta_title)
        setmetadescription(response.data.data.meta_description);
        setSchoolLoaded(true);
      }
    } catch (err) {
      console.error(err);
      history.push("/404");
    }
  }, [isMountedRef]);

  const formattedData = schooldetail && schooldetail.schfaqs && schooldetail.schfaqs.map((item) => ({
    "@type": "Question",
    "name": item.questions,
    "acceptedAnswer": {
      "@type": "Answer",
      "text": item.answers,
    },
  }));

  useEffect(() => {
    getdatabyschoolid();
    getHeroData();
    getSchoollist();
    //getCollageUnivData();
    //geBlogData();
  }, []);

  return (
    <>
      {isSchoolLoaded ?
        <>
          <Helmet>
            <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
            <meta name="description" content={metadescription && metadescription != '' ? metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
            <link rel="canonical" href={`${pConfig.siteUrl}school/${slug}`} />
            {/* <script type="application/ld+json">
              {JSON.stringify(
                {
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": formattedData,
                }
              )}
            </script> */}
          </Helmet>
          <Header schooldetail={schooldetail} slug={slug} />
          <Breadcrumbs schooldetail={schooldetail} />
          <React.Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', height: '600px', flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div>}>
            <OverviewSection schooldetail={schooldetail} slug={slug} />
          </React.Suspense>
          <Features schooldetail={schooldetail} />
          <Interested schooldetail={schooldetail} />
          <Location schooldetail={schooldetail} />
          <GlobalReviewSection type="school" item_id={schooldetail.id ? schooldetail.id : 0} />

          <TopColleges topschoolArr={topschoolArr} />
        </>
        :
        <div style={{ display: 'flex', justifyContent: 'center', height: '600px', flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div>}
    </>
  );
};

export default HomePage;